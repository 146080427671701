
import universal, { setHasBabelPlugin } from '/opt/atlassian/pipelines/agent/build/node_modules/react-universal-component/dist/index.js'


setHasBabelPlugin()

const universalOptions = {
  loading: () => null,
  error: props => {
    console.error(props.error);
    return <div>An error occurred loading this page's template. More information is available in the console.</div>;
  },
}

const t_0 = universal(import('../node_modules/react-static/lib/browser/components/Default404'), universalOptions)
const t_1 = universal(import('../src/catapultlabs/pages/home'), universalOptions)
const t_2 = universal(import('../src/catapultlabs/pages/about-us'), universalOptions)
const t_3 = universal(import('../src/standbot/pages/home'), universalOptions)
const t_4 = universal(import('../src/standbot/pages/jira-connection'), universalOptions)
const t_5 = universal(import('../src/standbot/pages/privacy-faqs'), universalOptions)
const t_6 = universal(import('../src/freshdesk-trello/pages/home'), universalOptions)
const t_7 = universal(import('../src/freshdesk-trello/pages/install'), universalOptions)
const t_8 = universal(import('../src/freshdesk-trello/pages/white-paper'), universalOptions)
const t_9 = universal(import('../src/freshdesk-trello/pages/documentation'), universalOptions)
const t_10 = universal(import('../src/freshservice-bitbucket/pages/home'), universalOptions)
const t_11 = universal(import('../src/freshservice-bitbucket/pages/documentation'), universalOptions)
const t_12 = universal(import('../src/freshservice-bitbucket/pages/install'), universalOptions)
const t_13 = universal(import('../src/freshservice-trello/pages/home'), universalOptions)
const t_14 = universal(import('../src/freshservice-trello/pages/documentation'), universalOptions)
const t_15 = universal(import('../src/freshservice-trello/pages/install'), universalOptions)
const t_16 = universal(import('../src/retrospectives-confluence/pages/home'), universalOptions)
const t_17 = universal(import('../src/retrospectives-jira/pages/home'), universalOptions)
const t_18 = universal(import('../src/scrum-poker-confluence/pages/home'), universalOptions)
const t_19 = universal(import('../src/scrum-poker-jira/pages/home'), universalOptions)
const t_20 = universal(import('../src/retrospectives/pages/home'), universalOptions)
const t_21 = universal(import('../src/retrospectives-monday/pages/home'), universalOptions)
const t_22 = universal(import('../src/agile-suite/pages/home'), universalOptions)
const t_23 = universal(import('../src/retrospectives-trello/pages/home'), universalOptions)


// Template Map
export default {
  '../node_modules/react-static/lib/browser/components/Default404': t_0,
'../src/catapultlabs/pages/home': t_1,
'../src/catapultlabs/pages/about-us': t_2,
'../src/standbot/pages/home': t_3,
'../src/standbot/pages/jira-connection': t_4,
'../src/standbot/pages/privacy-faqs': t_5,
'../src/freshdesk-trello/pages/home': t_6,
'../src/freshdesk-trello/pages/install': t_7,
'../src/freshdesk-trello/pages/white-paper': t_8,
'../src/freshdesk-trello/pages/documentation': t_9,
'../src/freshservice-bitbucket/pages/home': t_10,
'../src/freshservice-bitbucket/pages/documentation': t_11,
'../src/freshservice-bitbucket/pages/install': t_12,
'../src/freshservice-trello/pages/home': t_13,
'../src/freshservice-trello/pages/documentation': t_14,
'../src/freshservice-trello/pages/install': t_15,
'../src/retrospectives-confluence/pages/home': t_16,
'../src/retrospectives-jira/pages/home': t_17,
'../src/scrum-poker-confluence/pages/home': t_18,
'../src/scrum-poker-jira/pages/home': t_19,
'../src/retrospectives/pages/home': t_20,
'../src/retrospectives-monday/pages/home': t_21,
'../src/agile-suite/pages/home': t_22,
'../src/retrospectives-trello/pages/home': t_23
}

export const notFoundTemplate = "../node_modules/react-static/lib/browser/components/Default404"
