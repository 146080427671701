// Imports


// Plugins
const plugins = [{
  location: "/opt/atlassian/pipelines/agent/build/node_modules/react-static-plugin-sass",
  plugins: [],
  hooks: {}
},
{
  location: "/opt/atlassian/pipelines/agent/build",
  plugins: [],
  hooks: {}
}]

// Export em!
export default plugins