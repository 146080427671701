import React from 'react'
import { Root, Routes, withSiteData } from 'react-static'
import './app.scss'
// import TagManager from 'react-gtm-module'


class App extends React.Component{
  componentDidMount(){
    // const tagManagerArgs = {
    //   gtmId: 'GTM-KCTJ872'
    // }
    // if(this.props.env !== 'PRODUCTION'){
    //   tagManagerArgs.auth = 'YoR0eacSsLYb_QADOxpWLA';
    //   tagManagerArgs.preview = 'env-6';
    // }

    // TagManager.initialize(tagManagerArgs);
  }

  render() {
    return (
      <Root>
        <div className="content">
          <Routes/>
        </div>
      </Root>
    )
  }
}

export default withSiteData(App)
